<template>
    <div class="page-admin-users table-page__thead--sticky">
        <card title="Filter" bodyClass="overflow-visible" class="border-radius card-header--border-none">
            <div slot="tools" class="flex">
                <button v-if="!disabledReset" class="flex flex__align-center text-body" @click="resetFilter">
                    <svg-importer icon-name="icons/reset" class="mr-2" /> Reset
                </button>
            </div>

            <div class="flex flex__align-end w-full">
                <div class="flex flex__grow">
                    <modal-field label="Organization" class="relative w-full pr-4">
                        <multiselect v-model="filters.organization" 
                            :options="organizations"
                            :multiple="false"
                            :value="organizations.id"
                            @input="populateSitesDropdown"
                            placeholder="Please select" 
                            track-by="id"
                            label="name">
                            <span slot="noResult">
                            Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>
                    </modal-field>
                </div>
                <div class="flex flex__grow">
                    <modal-field label="Site" class="relative w-full pr-4">
                        <multiselect v-model="filters.site_id" 
                            :options="sites"
                            :multiple="false"
                            :disabled="!filters.organization"
                            placeholder="Please select" 
                            track-by="id"
                            label="name">
                            <span slot="noResult">
                            Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>
                    </modal-field>
                </div>
                <div class="flex flex__grow">
                    <modal-field label="Role" class="relative w-full pr-4">
                        <multiselect v-model="filters.role_id" 
                            :options="roles"
                            :multiple="false"
                            placeholder="Please select" 
                            track-by="id"
                            label="name">
                            <span slot="noResult">
                            Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>
                    </modal-field>
                </div>
                <router-link
                    :to="{ path: '/admin/user/create' }"
                    class="inline-block"
                    :class="[routeIncludesAdmin ? 'btn-primary' : 'btn']"
                >
                        Add user
                </router-link>
            </div>
        </card>

        <card class="bg-transparent" bodyClass="p-0 -mx-6 mb-6">   
            <v-table
                class="admin-table"
                :id="'users'"
                :columns="columns"
                row-key="uuid"
                ref="users"
                :endpoint="$apiUrl.users.list"
                :item-size="49"
                sort-key="created_at"
                :filter-params="filterParams"
                query-params
                :loading="loadingUsers"
                @row-click="rowClicked"
            >
                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="col.key == 'users'" class="dotted-underline">
                        {{ row[col.key].length }} users
                    </span>
                    <span v-else-if="col.key == 'roles'">
                        {{ getRolesNames(row[col.key]) }}
                    </span>
                    <span class="flex flex__justify-end w-full text-right" v-else-if="col.key == 'edit'">
                        <button @click="editUser(row.id)">
                            <svg-importer icon-name="icons/edit" :key="`user_${row.id}_edit`" />
                        </button>

                        <button @click="removeUser(row.id)" class="ml-6" href="">
                            <svg-importer width="20" icon-name="icons/close" :key="`user_${row.id}_close`" />
                        </button>
                    </span>
                    <span v-else-if="col.key == 'organization'">{{ row[col.key].name }}</span>
                    <div v-else-if="col.key == 'site'" class="w-48 text-ellipsis">
                        {{ row[col.key] }}
                    </div>
                    <span v-else-if="col.key === 'templates'" :title="getTemplatesButtonTitle(row[col.key])">
                        <button
                            class="underline"
                            @click="openTemplatesModal(row)"
                        >
                            {{ row[col.key].length }} selected
                        </button>
                    </span>
                    <span v-else>{{ row[col.key] || '-' }}</span>
                </template>
            </v-table>
        </card>
         <delete-modal
            :show="showDeleteModal"
            @hide="closeDeleteModal"
            name="User"
            :selectedObject="selectedUser"
            :organization="selectedOrganization"
            :organizations="organizations"
            :sites="sites"
            @delete="initDelete"            
        >
        </delete-modal>

        <users-visibility-modal
        :show="isShowTemplatesModal"
        :entity="selectedTemplateUser"
        :cols="templatesCols"
        :rows="templatesRows"
        :endpoint="(val) => `/api/users/${val}/templates/`"
        payload-field-name="templates"
        @hide="closeTemplatesModal"
        @update="updateRows"
        />
    </div>
</template>

<script>

    import axios from 'axios'
    import UsersVisibilityModal from '~/components/PriceLeadTime/UsersVisibilityModal.vue';
    import columns from '~/data/columns-users.json';

    const templatesCols = [
        {
            title: '',
            key: 'checked',
        },
        {
            title: 'Template Name',
            key: 'name',
        },
        {
            title: 'Description',
            key: 'description',
        },
        {
            title: 'Carrier',
            key: 'carrier_name',
        },
        {
            title: 'Service',
            key: 'carrier_service_name',
        },
    ];

    export default {

        components: {
            UsersVisibilityModal,
        },

        metaInfo () {
            return { title: 'Sites' }
        },

        data: () => ({
            organizations: [],
            sites: [],
            roles: [],
            filters: {
                organization: null,
                site_id: null,
                role_id: null,
            },
            users: [],
            showDeleteModal: false,
            selectedUser: null,
            selectedSite: null,
            selectedOrganization: null,
            loadingUsers: false,
            showDelete: false,
            filterParams: {},
            columns,
            selectedTemplateUser: null,
            templatesCols,
            templatesRows: [],
            isShowTemplatesModal: false,
            carriers: [],
        }),

        created() {
            this.fetchOrganizations();
            this.fetchUsers();
            this.fetchRoles();
            this.fetchCarriers();
        },

        watch: {
            filters:{
                handler(){
                    this.setFilterParams();
                    
                },
                deep: true
            }
        },

        computed: {
            routeIncludesAdmin() {
                return this.$route.path.includes('admin') ?  true : false;
            },
            disabledReset() {
                return !this.filters.organization && !this.filters.site_id && !this.filters.role_id
            }
        },

        methods: {
            getTemplatesButtonTitle(templates) {
                return templates.map(template => template.name)?.join(', ');
            },
            async openTemplatesModal(user) {
                this.selectedTemplateUser = user;
                const templateUsersIdsSet = new Set(user.templates.map(template => template.id));
                let companyTemplates = await this.fetchTemplates(user.organization.id) 
                companyTemplates = companyTemplates
                    ?.filter(template =>  !templateUsersIdsSet.has(template.id));

                this.templatesRows = [...user.templates, ...companyTemplates]
                    .sort((a) => {
                        if (templateUsersIdsSet.has(a.id)) {
                            return -1;
                        } else {
                            return 1;
                        }
                    })
                    .map(template => {
                        const newTemplate = Object.assign({}, template);
                        newTemplate.checked = templateUsersIdsSet.has(template.id);

                        if (newTemplate.carrier_id) {
                            newTemplate.carrier = this.carriers.find(carrier => carrier.id === newTemplate.carrier_id);
                            newTemplate.carrier_name = newTemplate.carrier?.name;
                        }

                        if (newTemplate.carrier && newTemplate.carrier_service_id) {
                            newTemplate.carrier_service_name = newTemplate.carrier.services?.find(service => service.id === newTemplate.carrier_service_id)?.name;
                        }

                        return newTemplate;
                    });
                this.isShowTemplatesModal = true;
            },
            closeTemplatesModal() {
                this.isShowTemplatesModal = false;
                this.selectedTemplateUser = null;
                this.templatesRows = [];
            },
            updateRows() {
                this.$refs.users?.table.fetchRowsSilent();
            },
            getRolesNames(roles) {
                return roles?.map(role => role.name).join(', ');
            },
            editUser(id) {
                this.$router.push({
                    name: "admin.users.edit",
                    params: {
                        id: id
                    }
                });
            },
            closeDeleteModal() {
                this.showDeleteModal = false;
                //this.selectedOrganization = null,
                this.selectedSite = null;
                //this.newOrganization = null;
                //this.showMoveUsers = false;
            },
            populateSitesDropdown() {
                this.sites = [];
                this.filters.site_id = null;

                if(this.filters.organization?.hasOwnProperty('sites')) {
                    this.sites = this.filters.organization.sites;
                }
            },
            setFilterParams() {
                let keys = Object.keys(this.filters);

                let params = {}

                keys.forEach(filter => {
                    if(this.filters[filter] && filter != 'range'){
                        params['filter[' + filter + ']'] = this.filters[filter].id ? this.filters[filter].id : this.filters[filter]
                    }
                })

                this.filterParams = params;
            },
            removeUser(id) {
                this.showDeleteModal = true;
                this.selectedUser = this.users.find(user => user.id == id);
            },
            async fetchUsers() {
                const { data } = await axios.get(this.$apiUrl.users.list + "?pageSize=1000");
                this.users = data.data;
            },
            async deleteUser() {
                const { data } = await axios.delete(`${this.$apiUrl.users.list}/` + this.selectedUser.id);
                this.showDelete = false;
                this.$refs.users.table.fetchRows();
                this.closeDeleteModal();
            },
            resetFilter() {
                this.sites = [];
                this.filters.organization = null;
                this.filters.site_id = null;
                this.filters.role_id = null;
                this.roles = [];
            },
            async fetchOrganizations() {
                const { data } = await axios.get(this.$apiUrl.organizations.base + '?pageSize=1000&include=sites');                
                this.organizations = data.data;
            },
            async fetchRoles() {
                const { data } = await axios.get(`${this.$apiUrl.roles.base}?pageSize=1000`);
                this.roles = data.data;
            },
            async fetchCarriers() {
                const { data: { data } } = await axios.get(`${this.$apiUrl.carriers.base}?pageSize=1000`);

                this.carriers = data;
            },
            async fetchTemplates(id) {
                try {
                    const { data: { data } } = await axios.get(this.$apiUrl.consignments.booking.templates + `?filter[organization_id]=${id}&pageSize=1000`);
                    return data;
                } catch(error) {
                    this.$snotify.error('Failed to fetch user\'s organization templates');
                }
            },
            initDelete() {
                this.deleteUser();
            },  
            rowClicked() {

            },
        }
    }
</script>

<style lang="scss">
.page-admin-users {
    .table-wrapper {
        max-height: calc(100vh - 324px);
        overflow: auto;
    }
}
</style>
